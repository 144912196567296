import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, Link} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import {Rain} from './components/rain';
import {RainEXP} from './components/rainEXP';
import {NavList} from './components/navlist';
import {Projects} from './pages/projects'
import {GameDev} from './pages/gamedev'
import {About} from './pages/about'
import {Experience} from './pages/experience'

function App() {


  return (
    <BrowserRouter>
    <div>
      <div class="borderDiv top"></div>
      <div class="borderDiv bottom"></div>
      <div class="borderDiv topLine"></div>
      <div class="borderDiv bottomLine"></div>
      <div class="borderDiv left"></div>
      <div class="container">
        <div style={{ position: 'fixed' }} class="navigationDiv">
          <h1 class="nameTitle">Rose Kelly</h1>
          <h3>Software Engineer</h3>
          <NavList/>
        </div>
        <div  class="navigationDiv"></div>
        <div class="pageContent">
            <Routes>
            <Route path="/" >
              <Route index element={<About/>} />
              {/* <Route path="experience" element={<Experience/>} /> */}
              <Route path="projects" element={<Projects/>} />
              <Route path="gamedev/:scroll" element={<GameDev/>} />
              <Route path="gamedev" element={<GameDev/>} />
            </Route>
            </Routes>
        </div>
      </div>
      <Rain/>
      
      {/* <RainEXP/> */}
    </div>
    </BrowserRouter>
  );
}

export default App;
