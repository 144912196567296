import React from "react";
import './projectSummary.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const ProjectSummary = ({ title, imgs=[], ytVideos=[], description1, description2, description3, skills=[], links=[]}) => {
    const skills2 = [{type: "language", skill: "Python"}, {type: "framework", skill: "React"}, {type: "gamedevtool", skill: "Maya"}]
    const skillTypeColorMap = {
        "language": "#659fb8",
        "framework": "#c84a67",
        "gamedevtool": "#2f7146"
    }
    const slides = [];
    for (let i = 0; i < imgs.length; i += 1) {
        slides.push(
            <SwiperSlide><img class="projectImage" src={imgs[i]}/></SwiperSlide>
        );
    }
    for (let i = 0; i <ytVideos.length; i += 1) {
        slides.push(
            <SwiperSlide>
                <iframe src={ytVideos[i]}
                style={{height:'calc(13vw + 13vh)'}}
                width='100%'
                frameborder='0'
                allow='autoplay; encrypted-media'
                allowfullscreen
                title='video'/>
            </SwiperSlide>
        );
    }

  console.log("links")
  console.log(links)
  const linkATags = [];
  for (let i = 0; i < links.length; i += 1) {
    linkATags.push(
        <li>
        <a>{links[i].description}</a> <a className="linkhref" href={links[i].url}>{links[i].url}</a> 
        </li>
    );
  }
	return (
    <div class="projectDescription">
        <div>
            <h2>{title}</h2>
        </div>
        <div class="projectDescriptionContents">
            <div className="projectDescriptionContentsText">
            {title==="HackReality Game Jam 2020 - VR Climbing Game" ? <a>Awarded <b style={{color: "gold"}}>Best Graphics/3D Modeling</b> and <b style={{color: "gold"}}>2nd Place - Best Use of AR/VR for Entertainment/Games</b><br></br><br></br></a>: <b> </b>}
                <a>{description1}</a>
                <br></br>
                <br></br>
                <a>{description2}</a>
                <br></br>
                <br></br>
                <a>{description3}</a>
            </div>
            <div className="projectDescriptionContentsImageDiv">
            <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      navigation={true}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      {slides}
    </Swiper>
                <div className="projectSkillsDiv">
                    {skills.map((item, index) => (
                        <div style={{ background: item.type === null? 'gray': skillTypeColorMap[item.type]}} key={item.skill}>
                            <a >{item.skill}</a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="linksDiv">
        {linkATags}
        </div>
        {/* <div className="linksDiv">
            <div><b>Links</b></div>
            <a href="https://duckduckgo.com/">Github      </a> <a href="https://duckduckgo.com/">          Devpost</a>
        </div> */}
    </div>
  );
};