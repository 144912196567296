import React, { useState } from "react";
import './projects.css';
import KFLogo from '../../assets/KyokosFriends/KyokosFriendsLogo.png'
import KFSS from '../../assets/KyokosFriends/KyokosFriendsSS.png'
import PeskiChart from '../../assets/Peski/PeskiTranspilerChart.png'
import PeskiSample from '../../assets/Peski/PeskiCodeSample.png'
import {ProjectSummary} from '../../components/projectSummary/index.js'

export const Projects = () => {
	const [currProject, setCurrProject] = useState("projectSelectionCol");

	return (
    <div class="projects">
		<ProjectSummary title={"\"Peski\" Programming Language Transpiler"} description1={"A transpiler for a novel language I designed I'm calling \"Peski\"! Peski code (pictured) uses ASCII art like pictures as keywords, making coding a more visually appealing--although not quite as straightforward--experience."} 
		description2 = {"This was made as an extension of a Compilers course project where I had to make a compiler for a simplified C language, so Peski is essentially a basic version of C with ASCII pictures instead of the normal keywords."}
		description3 = {"The repo linked below includes Flex and Bison files for tokenizing and parsing the Peski code and transpiling it into C, and a bash script that runs the transpilation process and then compiles and runs that transpiled C to seemlessly compile and run your Peski code with one script. If you want to write and run your own Peski code the repo includes instructions on how to do so."}
		skills={[{type: null, skill: "Flex"}, {type: null, skill: "Bison"}, {type: "language", skill: "C"}, {type: "language", skill: "Bash"}]}
		imgs={[PeskiSample, PeskiChart]}
		links={[{description: "Github Repo: ", url: "https://github.com/rosekelly6400/peski-transpiler "}]} />

		<ProjectSummary title={"2023 Portfolio Website"} description1={"Single Page Application made using React and hosted on Google Cloud Platform."} 
		description2 = {"I created the raindrop animation in the background using CSS animations."}
		description3 = {""}
		skills={[{type: "framework", skill: "React"}, {type: "language", skill: "Javascript"}, {type: "language", skill: "CSS"}, {type: null, skill: "GCP"}]} />
		{/* <div class="projectDescription">
			<div>
				<h2>Kyoko's Friends</h2>
			</div>
			<div class="projectDescriptionContents">
				<div><a>In this project</a></div>
				<div><img class="projectImage" src={KFLogo}/></div>
			</div>
			<div><a>Links for project go here</a></div>
		</div> */}
		{/* <div class="projectSelection">
			<div className="projectSelectionCol">
				<img className="projectIcon" src={KFLogo}/> <h2>Kyoko's Friends</h2>
			</div>
			<div className="projectSelectionCol" onMouseEnter={() => {setCurrProject("none")}}>
				<img className="projectIcon" src={KFLogo}/> <h2>Kyoko's Friends</h2>
			</div>
			<div className={(currProject==="Kyoko's Friends" ? 'projectSelectionColACTIVE' : 'projectSelectionCol')} onMouseEnter={() => {setCurrProject("Kyoko's Friends")}}>
				<img className="projectIcon" src={KFLogo}/> <h2>Kyoko's Friends</h2>
			</div>
		</div> */}
	</div>
  );
};