import { RainSquare } from './rainSquare';
import './rain.css';

export const Rain = () => {
  return (
    <div class="rainContainer">
		<RainSquare topDistance = "-3vh"  delay="3s"/>
		<RainSquare topDistance = "35vh" leftDistance="21vw" delay="1s"/>
		<RainSquare topDistance = "65vh" leftDistance="-2vw" delay="5.3s"/>
		<RainSquare topDistance = "6vh" leftDistance="28vw" delay="4.5s"/>
		<RainSquare topDistance = "60vh" leftDistance="50vw" delay="2.5s"/>
		<RainSquare topDistance = "40vh" leftDistance="55vw" delay="3.8s"/>
		<RainSquare topDistance = "20vh" leftDistance="80vw" delay="1.7s"/>
		<RainSquare topDistance = "-4vh" leftDistance="60vw" delay="3.2s"/>
	</div>
  );
};
