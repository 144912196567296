import './rain.css';

export const RainSquare = ({topDistance="0vw", leftDistance="0vw", delay="0s"}) => {
    const ripple1Delay = getComputedStyle(document.documentElement)
    .getPropertyValue('--ripple1-delay');
    const ripple2Delay = getComputedStyle(document.documentElement)
    .getPropertyValue('--ripple2-delay');
    const sprayDelay = getComputedStyle(document.documentElement)
    .getPropertyValue('--spray-delay');
    function addStringsWithS(str1, str2) {
        // Extract the numeric parts from the input strings
        const num1 = parseFloat(str1);
        const num2 = parseFloat(str2);
      
        // Check if the extraction was successful
        if (!isNaN(num1) && !isNaN(num2)) {
          // Add the numeric parts
          const sum = num1 + num2;
      
          // Convert the result back to a string ending with 's'
          const result = sum.toString() + "s";
      
          return result;
        } else {
          return "Invalid input";
        }
      }
      
  return (
	<div  class="rainSquare" style={{top: topDistance, left: leftDistance}}>
		<div class="rainHolder" ><div class="ripple" style={{ animationDelay: addStringsWithS(delay, ripple1Delay)}} ></div></div>
		<div class="rainHolder"><div class="ripple2" style={{ animationDelay: addStringsWithS(delay, ripple2Delay)}} ></div></div>
		<div class="rainHolder"><div class="raindrop" style={{ animationDelay: delay}} ></div></div>
		<div class="rainHolder"><div class="sprayDrop" style={{ animationDelay: addStringsWithS(delay, sprayDelay)}} ></div></div>
		<div class="rainHolder"><div class="sprayDrop2" style={{ animationDelay: addStringsWithS(delay, sprayDelay)}} ></div></div>
	</div>
  );
};
